import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'add',
    'template',
    'list'
  ];

  add(event) {
    event.preventDefault();
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf());
    this.listTarget.insertAdjacentHTML('beforeend', content);
  }

  remove(event) {
    event.preventDefault();
    let item = event.target.closest('.step-fields');
    item.querySelector('input[name*="_destroy"]').value = 1;
    item.style.display = 'none';
    // jquery(item).slideUp(1000);
  }

}
