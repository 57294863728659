// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import "trix"
import Rails from "@rails/ujs"
Rails.start()


// @rails/actiontext is currently broken (missing a .js file extension or some other configuration)
// do what the file is doing here instead so it works and compiles properly


import "@rails/actiontext"
// import { AttachmentUpload } from "@rails/actiontext/app/javascript/actiontext/attachment_upload"
//
// addEventListener("trix-attachment-add", event => {
//   const { attachment, target } = event
//
//   if (attachment.file) {
//     const upload = new AttachmentUpload(attachment, target)
//     upload.start()
//   }
// })
