import { Controller } from "@hotwired/stimulus"

let debounce = require('lodash/debounce');

export default class extends Controller {

  static targets = ["input", "output", "index"]

  connect() {
    this.onInputChange = debounce(this.onInputChange.bind(this), 300)
    this.onInputBlur = this.onInputBlur.bind(this)
    this.onKeydown = this.onKeydown.bind(this)

    this.inputTarget.addEventListener('input', this.onInputChange)
    this.inputTarget.addEventListener('blur', this.onInputBlur)
    this.inputTarget.addEventListener('keydown', this.onKeydown)
  }

  disconnect() {
    this.inputTarget.removeEventListener('input', this.onInputChange)
    this.inputTarget.removeEventListener('blur', this.onInputBlur)
    this.inputTarget.removeEventListener('keydown', this.onKeydown)
  }

  onInputChange() {
    this.fetchResults()
  }

  onInputBlur() {
    // console.log('onInputBlur')
  }

  onKeydown(event) {
    switch(event.key) {
      case 'Escape':
        this.outputTarget.classList.remove('d-block')
        this.outputTarget.classList.add('d-none')

        this.indexTarget.classList.remove('d-none')
        this.indexTarget.classList.add('d-block')
      break
      case 'Enter':
        // console.log('ENTER key hit in input target:', event)
      break
    }
  }

  fetchResults() {
    const query = this.inputTarget.value.trim()

    if (!query) {
      this.outputTarget.classList.remove('d-block')
      this.outputTarget.classList.add('d-none')

      this.indexTarget.classList.remove('d-none')
      this.indexTarget.classList.add('d-block')

      return
    }

    const url = new URL(this.data.get('url'), window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append('query', query)
    url.search = params.toString()

    fetch(url.toString())
      .then(response => response.text())
      .then(html => {
        this.indexTarget.classList.remove('d-block')
        this.indexTarget.classList.add('d-none')

        this.outputTarget.innerHTML = html

        this.outputTarget.classList.remove('d-none')
        this.outputTarget.classList.add('d-block')
      })
      .catch(error => {
        console.log('Error:', error)
      })
  }

}
